import { useMemo, useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import style from './index.module.less';
import OurModal from '@/components/Modal/index';
import { Button, message } from 'antd';
import dynamic from 'next/dynamic';
const Dropdown = dynamic(() => import('antd/lib/dropdown'), {
  ssr: false,
});
import type { MenuProps } from 'antd';
import { DownOutlined, CopyOutlined, CloseOutlined } from '@ant-design/icons';
import {
  postSeachSocialAccountApi,
  postAccountAddApi,
  postEditAccountStatustApi,
  getUploadAccountIdApi,
} from '@/lib/service';
import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import { platFormsIconsMap } from '@/lib/hooks/utils/plateforms';
import { useConfigProvider } from '@/context/ConfigProvider';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { useTranslation } from 'next-i18next';
import AnimatedProtectionModal from './animated-protection-modal';
import AddAccountInput from './AddAccountInput/index';
interface Iprops {
  isModalOpen: boolean;
  handleCancel: () => void;
  upDataAccountList?: () => void;
  isHome?: boolean;
  ref?: any;
  newAccountData?: () => void;
}

// 校验辅助函数
export const verifyDescFunc = (description: any) => {
  if (description) {
    if (description.indexOf('erasa') == -1) {
      return 2;
    } else {
      return 1;
    }
  } else {
    return 2;
  }
};

const Index: React.FC<Iprops> = forwardRef(
  ({ isModalOpen, handleCancel, upDataAccountList, isHome = false, newAccountData }, ref) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const [messageApi, contextHolder] = message.useMessage();
    const [verfyChange, setVerfyChange] = useState(0); //0 旧方式校验 1 新方式娇艳
    const [step, setStep] = useState(0); //一会儿要恢复状态0
    const [innerStep, setInnerStep] = useState(0);
    const [selectRow, setSelectRow] = useState('');
    const [pathname, setPathname] = useState('');
    const [step1Loading, setStep1Loading] = useState(false);
    const [info, setInfo] = useState<any>({});
    const [isCopy, setIsCopy] = useState(false);
    const { isLogin, onOpenLoginModal, userSub } = useUserProvider();
    const [isEdit, setIsEdit] = useState(false);
    const { isMobile } = useConfigProvider();
    const [showAniimatedProtection, setShowAniimatedProtection] = useState(false);

    useImperativeHandle(ref, () => ({
      trigger(row: any) {
        setInfo(row);
        setSelectRow(row.url);
        setInnerStep(2);
        setStep(1);
        setIsEdit(true);
      },
      triggerStep(url: string, host: string) {
        setInnerStep(1);
        setStep(0);
        setSelectRow(url || 'other');
        setPathname(host);
        setFlag(true);
      },
    }));

    useEffect(() => {
      if (isLogin && isHome) {
        getUploadAccountIdApi({ id: info?.id });
      }
    }, [isLogin]);

    // 免责声明
    const [isDisclaimerModal, setIsDisclasimerModal] = useState(false);
    const items: MenuProps['items'] = [
      {
        key: '1',
        type: 'group',
        label: t('Subscription Platforms'),
        children: [
          {
            key: '1-1',
            label: (
              <div onClick={() => setSelectRow('https://www.onlyfans.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/onlyfans.png"
                  alt=""
                />
                <span style={{}}>onlyfans.com</span>
              </div>
            ),
          },
          {
            key: '1-2',
            label: (
              <div onClick={() => setSelectRow('https://www.tiktok.com/@')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/tiktok.png"
                  alt=""
                />
                <span>tiktok.com</span>
              </div>
            ),
          },
          {
            key: '1-3',
            label: (
              <div onClick={() => setSelectRow('https://x.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/space.png"
                  alt=""
                />
                <span>x.com</span>
              </div>
            ),
          },
          {
            key: '1-4',
            label: (
              <div onClick={() => setSelectRow('https://www.instagram.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/ins.png"
                  alt=""
                />
                <span>instagram.com</span>
              </div>
            ),
          },
          {
            key: '1-5',
            label: (
              <div onClick={() => setSelectRow('https://www.patreon.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/patreon.png"
                  alt=""
                />
                <span>patreon.com</span>
              </div>
            ),
          },

          {
            key: '1-6',
            label: (
              <div onClick={() => setSelectRow('https://www.chaturbate.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/chaturbate.png"
                  alt=""
                />
                <span>chaturbate.com</span>
              </div>
            ),
          },
          {
            key: '1-7',
            label: (
              <div onClick={() => setSelectRow('https://www.fansly.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/fansly.png"
                  alt=""
                />
                <span>fansly.com</span>
              </div>
            ),
          },
          {
            key: '1-8',
            label: (
              <div onClick={() => setSelectRow('https://www.fanvue.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/fanvue.png"
                  alt=""
                />
                <span>fanvue.com</span>
              </div>
            ),
          },
          {
            key: '1-9',
            label: (
              <div onClick={() => setSelectRow('https://www.manyvids.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/manyvids.png"
                  alt=""
                />
                <span>manyvids.com</span>
              </div>
            ),
          },
          {
            key: '1-10',
            label: (
              <div onClick={() => setSelectRow('https://www.reddit.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/reddit.png"
                  alt=""
                />
                <span>reddit.com</span>
              </div>
            ),
          },
          {
            key: '1-11',
            label: (
              <div onClick={() => setSelectRow('https://www.pornhub.com/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/pornhub.png"
                  alt=""
                />
                <span>pornhub.com</span>
              </div>
            ),
          },
          {
            key: '1-12',
            label: (
              <div onClick={() => setSelectRow('https://fansone.co/')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/fansone.png"
                  alt=""
                />
                <span>fansone.co</span>
              </div>
            ),
          },
        ],
      },
      {
        key: '2',
        type: 'group',
        label: t('Other Platforms'),
        children: [
          {
            key: '2-1',
            label: (
              <div onClick={() => setSelectRow('other')}>
                <img
                  style={{
                    height: '20px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  src="/images/erasa/platforms/other.png"
                  alt=""
                />
                <span>Others</span>
              </div>
            ),
          },
        ],
      },
    ];
    const iconUrlMapRef = useRef(
      new Map([
        ['https://www.onlyfans.com/', '/images/erasa/platforms/onlyfans.png'],
        ['https://www.tiktok.com/@', '/images/erasa/platforms/tiktok.png'],
        ['https://x.com/', '/images/erasa/platforms/space.png'],
        ['https://www.instagram.com/', '/images/erasa/platforms/ins.png'],
        ['https://www.patreon.com/', '/images/erasa/platforms/patreon.png'],
        ['https://www.chaturbate.com/', '/images/erasa/platforms/chaturbate.png'],
        ['https://www.fansly.com/', '/images/erasa/platforms/fansly.png'],
        ['https://www.fanvue.com/', '/images/erasa/platforms/fanvue.png'],
        ['https://www.manyvids.com/', '/images/erasa/platforms/manyvids.png'],
        ['https://www.reddit.com/', '/images/erasa/platforms/reddit.png'],
        ['https://www.pornhub.com/', '/images/erasa/platforms/pornhub.png'],
        ['https://fansone.co/', '/images/erasa/platforms/fansone.png'],
        ['other', '/images/erasa/platforms/other.png'],
      ]),
    );

    const socialType = useMemo(() => {
      if (selectRow) {
        if (selectRow == 'other') {
          return '';
        } else {
          return selectRow.match(/https:\/\/(?:www\.)?([^\/]+)(?=(\.com|\.co))/)[1];
        }
      } else {
        return '';
      }
    }, [selectRow]);
    // 异步操作
    const handleCommit = () => {
      // 需求如此
      setInnerStep(1);
      const timer = setTimeout(() => {
        postSeachSocialAccountApi({
          username: pathname,
          social_type: socialType,
          url: (selectRow == 'other' ? '' : selectRow) + pathname,
        }).then((res) => {
          if (res.code == 0) {
            if (isLogin) {
              getUploadAccountIdApi({ id: res.data?.id });
            }
            clearTimeout(timer);
            setInnerStep(2);
            setInfo(res.data);
          } else {
            message.warning(res.message);
            setInnerStep(0);
          }
        });
      }, 2000);
    };
    const [flag, setFlag] = useState(false);
    //   确定 url
    const isValidUrl = (string: string) => {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    };
    const handleConfirmUrlClick = () => {
      ga_logEvent(EventName.User_AddModal_Ok);
      if (!socialType) {
        try {
          const urlObk = new URL(pathname);
          setSelectRow(urlObk.origin + '/');
          const len = urlObk.pathname.split('/');
          const temp_pathname = len[len.length - 1];
          setPathname(temp_pathname);
          setFlag(true);
          return;
        } catch {
          message.warning('Incorrect input format');
        }
      } else {
        if (isValidUrl(pathname)) {
          const urlObk = new URL(pathname);
          let len = [];
          if (urlObk.hostname.includes('tiktok')) {
            len = urlObk.pathname.split('@');
          } else {
            len = urlObk.pathname.split('/');
          }
          const temp_pathname = len[len.length - 1];
          setPathname(temp_pathname);
          setFlag(true);
          return;
        }
      }
      handleCommit();
    };

    useEffect(() => {
      if (flag) {
        handleCommit();
      }
    }, [flag]);

    // 下一步
    const handleNext = () => {
      if (innerStep == 0 || innerStep == 1) {
        return;
      }
      if (isHome && !isLogin) {
        onOpenLoginModal(true);
      } else {
        setStep(1);
        // if (isHome && !userSub?.item_id) {
        //   router.push(`/setting?social_account_id=${info.id}`);
        // } else {
        //   if (userSub?.item_id) {
        //     setStep(1);
        //   } else {
        //     messageApi.open({
        //       type: "warning",
        //       content: t(
        //         "No subscription information, jump to the subscription page in two seconds..."
        //       ),
        //       duration: 2.5,
        //     });
        //     const timer = setTimeout(() => {
        //       router.push("/plan");
        //       clearTimeout(timer);
        //     }, 2000);
        //   }
        // }
      }
    };
    // 重置
    const resetStatus = () => {
      setStep(0);
      setVerfyChange(0);
      setInnerStep(0);
      setPathname('');
      setSelectRow('');
      setInfo({});
      setIsEdit(false);
      setIsCopy(false);
      handleCancel();
      setFlag(false);
    };

    // 复制
    const handleCopy = (val: string) => {
      setIsCopy(true);
      const oInput = document.createElement('input');
      oInput.value = val;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand('Copy');
      oInput.remove();
      message.success('Content to copy to clipboard');
    };

    // 校验 desc
    const handlVerify = () => {
      ga_logEvent(EventName.User_addModal_Verify);
      setStep1Loading(true);
      if (isEdit) {
        // 编辑
        postSeachSocialAccountApi({
          username: info.username,
          social_type: socialType,
          url: info.url,
          verify_type: verfyChange == 0 ? 1 : 2,
        }).then((resIns) => {
          postEditAccountStatustApi(info.id, {
            verify_status: verifyDescFunc(resIns.data?.profile_description),
          }).then((rs) => {
            if (rs.code == 0) {
              upDataAccountList && upDataAccountList();
              resetStatus();
              setStep1Loading(false);
            } else {
              message.warning(rs.message);
            }
          });
        });
      } else {
        // 新增
        if (isCopy) {
          // 验证
          postSeachSocialAccountApi({
            username: pathname,
            social_type: socialType,
            url: selectRow + pathname,
            verify_type: verfyChange == 0 ? 1 : 2,
          }).then((resIns) => {
            postAccountAddApi({
              url: selectRow + pathname,
              name: info?.name,
              username: info?.username,
              avatar: info?.avatar_url,
              platform: socialType,
              description: info?.profile_description,
              verify_status: verifyDescFunc(resIns.data?.profile_description),
            }).then((rs) => {
              if (rs.code == 0) {
                message.success(rs.message);
                upDataAccountList && upDataAccountList();
                resetStatus();
                setStep1Loading(false);
                setIsDisclasimerModal(true);
                newAccountData && newAccountData();
              } else {
                setStep1Loading(false);
                message.warning(rs.message);
              }
            });
          });
        } else {
          // 没有验证
          postAccountAddApi({
            url: selectRow + pathname,
            name: info?.name,
            username: info?.username,
            avatar: info?.avatar_url,
            platform: socialType,
            description: info?.profile_description,
            verify_status: 0,
          }).then((rs) => {
            if (rs.code == 0) {
              upDataAccountList && upDataAccountList();
              resetStatus();
              setStep1Loading(false);
              setIsDisclasimerModal(true);
            } else {
              setStep1Loading(false);
              message.warning(rs.message);
            }
          });
        }
      }
    };

    return (
      <div className={style.container}>
        {contextHolder}
        <OurModal
          isModalOpen={isModalOpen}
          isNoAddAccount={false}
          handleCancel={() => {
            resetStatus();
          }}
          width={isMobile ? 400 : 850}
          errIconOut={true}
          zIndex={999}
        >
          <div className={style.main}>
            <div className={style.left}>
              <div className={style.titleStep}>
                <div>{step == 0 ? t('Add account') : t('Verify yourself')}</div>
              </div>
              {step == 0 ? (
                <>
                  <div className={style.desc}>
                    {t(
                      'Select the social media accounts you want Erasa to protect.Without this,we won’t be able to protect your content.',
                    )}
                  </div>
                  <div className={style.platformsItem}>{t('Platforms')}</div>
                  {innerStep == 0 && (
                    <div className={style.modalInput}>
                      <AddAccountInput
                        getAccount={(inputValue: string, selectedPlatform: string) => {
                          // setInnerStep(1);
                          setStep(0);
                          setPathname(inputValue || 'other');
                          setSelectRow(selectedPlatform);
                          setFlag(true);
                        }}
                      />
                    </div>
                  )}
                  {(innerStep == 1 || innerStep == 2) && (
                    <div className={style.plateforms}>
                      <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        autoAdjustOverflow={false}
                        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
                        overlayStyle={{
                          height: '300px',
                          overflow: 'auto',
                        }}
                        // placement="bottom"
                        disabled={innerStep == 1 || innerStep == 2}
                      >
                        <div className={style.addPlatform}>
                          <div>
                            <img
                              src={iconUrlMapRef.current.get(selectRow)}
                              alt={iconUrlMapRef.current.get(selectRow)}
                            />
                            <span>{selectRow || 'Add Account'}</span>
                          </div>
                          <div>
                            <DownOutlined />
                          </div>
                        </div>
                      </Dropdown>
                    </div>
                  )}
                  <div className={style.platformsItem}>
                    <div>{t('Add account handle')}</div>
                  </div>

                  {innerStep == 1 && (
                    <div className={style.account}>
                      <div className={style.loading}>
                        <div>{t('Protection for this account will start with Erasa.')}</div>
                        <div>
                          <img src="/images/erasa/protectedAccount/loading.webp" alt="" />
                          <span>{t('Check your accounts')}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {innerStep == 2 && (
                    <div className={style.account}>
                      <div className={style.info}>
                        <div className={style.left}>
                          <div>
                            <img src={info?.avatar_url || '/images/dashboard/defaultAvatar.png'} alt="" />
                            <img src={platFormsIconsMap.get(info?.social_type)} alt="" />
                          </div>
                          <div>
                            <div>{info?.name || '-'}</div>
                            <div>{info?.profile_description || '-'}</div>
                          </div>
                        </div>
                        <div
                          className={style.right}
                          onClick={() => {
                            setFlag(false);
                            setInnerStep(0);
                          }}
                        >
                          <CloseOutlined />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={style.desc}>
                    {t('Choose your verification method to authorize a DMCA takedown on your behalf. ')}
                  </div>
                  <div className={style.chanceRadio}>
                    <div onClick={() => setVerfyChange(0)}>
                      <div
                        style={{
                          backgroundColor: verfyChange == 0 ? '#0B193C' : '#dbdbdb',
                        }}
                      ></div>
                      <div>{t('Bio Verification')}</div>
                    </div>
                    <div onClick={() => setVerfyChange(1)}>
                      <div
                        style={{
                          backgroundColor: verfyChange == 1 ? '#0B193C' : '#dbdbdb',
                        }}
                      ></div>
                      <div>{t('Social Media')}</div>
                    </div>
                  </div>
                  <div className={style.newDesc}>
                    {verfyChange == 0
                      ? t('Add the following text to your bio to verify')
                      : t('Follow our social media and send the verification message')}
                  </div>
                  {verfyChange == 0 && (
                    <div className={style.chance1}>
                      <div className={style.copyDesc}>
                        <div>
                          <img src="/images/erasa/protectedAccount/right.webp" alt="" />
                          {t('All content is copyrighted . Protection enforced by erasa.')}
                        </div>
                        <div
                          onClick={() => {
                            handleCopy('All content is copyrighted . Protection enforced by erasa');
                            ga_logEvent(EventName.User_addModal_Copy);
                          }}
                        >
                          <CopyOutlined style={{ cursor: 'pointer' }} />
                        </div>
                      </div>
                    </div>
                  )}
                  {verfyChange == 1 && (
                    <div className={style.chance2}>
                      <ul className={style.platforms}>
                        <li
                          onClick={() => {
                            window.open('https://twitter.com/Erasaprotect');
                          }}
                        >
                          <img src="/images/erasa/platforms/space.png" alt="x" />
                          <span>{t('Follow on Twitter')}</span>
                        </li>
                        <li
                          onClick={() => {
                            window.open('https://www.instagram.com/erasaptotect/');
                          }}
                        >
                          <img src="https://res-front.pumpsoul.com/prod-erasa/image/ins-rect.webp" alt="ins" />
                          <span>{t('Follow on Instagram')}</span>
                        </li>
                      </ul>
                      <div className={style.copyDesc1}>
                        <div>{t('All content is copyrighted. Protection enforced by erasa.net')}</div>
                        <div
                          onClick={() => {
                            handleCopy('All content is copyrighted. Protection enforced by erasa.net');
                            ga_logEvent(EventName.User_addModal_Copy);
                          }}
                        >
                          <CopyOutlined style={{ cursor: 'pointer' }} />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {step == 0 && innerStep == 2 ? (
                <div className={style.btn_c1}>
                  <Button onClick={resetStatus}>{t('Cancel')}</Button>

                  <Button onClick={handleNext}>{t('Next')}</Button>
                </div>
              ) : step == 1 ? (
                <div className={style.btn_c2}>
                  <Button onClick={resetStatus}>{t('Cancel')}</Button>
                  {isCopy ? (
                    <Button onClick={handlVerify} className={style.copy} style={{}}>
                      {t('Continue')}
                    </Button>
                  ) : (
                    <Button onClick={handlVerify} loading={step1Loading} className={style.noCopy}>
                      {t('Skip for Now')}
                    </Button>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            {isMobile ? (
              <></>
            ) : (
              <div>
                <img
                  src={
                    step == 0
                      ? '/images/erasa/protectedAccount/step1.webp'
                      : '/images/erasa/protectedAccount/step2.webp'
                  }
                  style={{ height: '100%' }}
                  alt=""
                />
              </div>
            )}
          </div>
        </OurModal>

        <OurModal
          isModalOpen={isDisclaimerModal}
          handleCancel={() => {
            setIsDisclasimerModal(false);
            setShowAniimatedProtection(true);
          }}
          width={360}
        >
          <div className={style.disclaimer}>
            <div className={style.title}>{t('DISCLAIMER')}</div>
            <div className={style.desc}>
              {t(
                'User must have legal copyright ownership or authorized permission to request content removal via this account.',
              )}
            </div>
            <div className={style.btns}>
              <Button
                onClick={() => {
                  setIsDisclasimerModal(false);
                  setShowAniimatedProtection(true);
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={() => {
                  setIsDisclasimerModal(false);
                  setShowAniimatedProtection(true);
                }}
              >
                {t('Confirm')}
              </Button>
            </div>
          </div>
        </OurModal>

        {showAniimatedProtection && (
          <AnimatedProtectionModal
            onClick={(isOk) => {
              if (isOk) {
                if (isHome && !userSub?.item_id) {
                  router.push('/dashboard');
                } else {
                  router.push('/protected-Accounts');
                }
              }
              setShowAniimatedProtection(false);
            }}
          />
        )}
      </div>
    );
  },
);

export default Index;
